<template>
  <v-dialog v-model="dialog" max-width="800">
    <dialog-card title="Sequências modelos" @close="dialog = false">
      <v-list>
        <v-list-item
          v-for="tt in allTherapyTemplates"
          :key="tt.id"
          @click="selectTemplate(tt)"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ tt.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Criado por: {{ tt.createdBy }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </dialog-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TherapyTemplatesDialog",
  computed: {
    dialog: {
      get() {
        return this.$store.state.sequence.therapyTemplates.dialog;
      },
      set(value) {
        this.$store.commit("sequence/therapyTemplates/setDialog", value);
      },
    },
    allTherapyTemplates() {
      return this.$store.state.sequence.therapyTemplates.allTherapyTemplates;
    },
  },
  mounted() {
    this.$store.dispatch(
      "sequence/therapyTemplates/populateAllTherapyTemplates"
    );
  },
  methods: {
    selectTemplate(selectedTemplate) {
      this.$store.dispatch(
        "sequence/therapyTemplates/selectTemplate",
        selectedTemplate.id
      );
    },
  },
};
</script>

<style scoped></style>
